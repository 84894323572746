.about-hero{
  position: relative;
  display: inline-block;
  min-height: 90vh;
}
.hero-image{
  content: '';
  position: block;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.about-hero:after{
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 35%,rgba(255, 255, 255, 0.953) 60%);
}

.hero-content{
  position: absolute;
  z-index: 2;
  top: 33%;
  left: 6em;
}
.hero-content h2{
  font-size: 2.4rem;
}
.hero-paragraph{
  padding-top: 0.7em;
  width: 30em;
  margin-bottom: 1em;
}

.ceo-picture img{
  width: 25em;
  height: 28em;
  object-fit: cover;
}

.ceo-message{
  background-color: rgb(255, 255, 255);
  width: 50em;
  padding: 2em;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.082) ;
  margin-left: -10px;
}


.leader-list img{
  height: 7em;
  width: 7em;
  object-fit: cover;
  object-position: top;
  border-radius: 100%;
}
.leaders-box{
  position: relative;
  min-height: 50vh;
}

.background-grad{
  position: block;
  content: '';
  background-color: rgb(245, 245, 245);
  bottom: 0;
  height: 9em;
  margin-top: -12em;
}

@media (max-width: 780px) {
  .background-grad{
    position: block;
    content: '';
    background-color: rgb(245, 245, 245);
    bottom: 0;
    height: 7.6em;
    margin-top: -7em;
  }
}
.our-team-content{
  width: 40vw;
}

.mission-pic{
  height: 10em;
  width: 22em;
  object-fit: cover;
}

.partner-link img {
  width:6em;
  height:6em;
  object-fit: contain;
}

.partner-link{
  width: 13em;
  height: 6em;
  background-color: white;
}
.partner-link:hover{
  box-shadow: 0px 0px 5px rgb(223, 223, 223);
  border-radius: 5px;
}

.worked-with-list img{
  width: 7em;
  height: 7em;
  object-fit: contain;
}

.worked-with-list{
  width: 15em;
  height: 8em;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 5px;
}

.worked-with-list:hover{
  box-shadow: 0px 0px 7px rgb(236, 236, 236);
}