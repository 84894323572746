.logo-section img{
  height: 2em;
}

.footer{
  height: 17em;
}

.link-section ul li h2{
  font-size: 0.9em;
  margin-top: 0.4em;
  padding-left: 1em;
  font-weight: 200;
}

.link-section h2{
  font-size: 1.2em;
}

@media(max-width: 800px) {
  .link-section ul li h2{
    font-size: 0.9em;
    margin-top: 0.4em;
    padding-left: 0em;
    font-weight: 200;
  }
}