.hero-capability{
  position: relative;
  display:inline-block;
}

.hero-capability:after{
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.864) 32%,rgba(0, 0, 0, 0.06) 38%); /* W3C */ 
}

.hero-image img{
  content: '';
  position: absolute;
  height: 100vh;
  object-fit: cover;
  width: 100vw;
  top: 0%;
  left: 0%;
}

.hero-text{
  content: '';
  position: absolute;
  top: 45%;
  left: 70%;
  z-index: 8;
  box-sizing: content-box;
  width: 25em;
}
.hero-text h2{font-size: 2em; color: white;}


.tough-project-list{
  position: relative;
  display: inline-block;
}
.tough-project-list:after{
  content: '';
  position: absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.749) 45%,rgba(0, 0, 0, 0.06) 58%);
}

.img-tough-project img{
  height: 25em;
  object-fit: cover;
}
.content-tough-project{
  content: '';
  position: absolute;
  z-index: 9;
  top: 55%;
}

.img-box-project img {
  height: 15em;
  width: 20em;
  object-fit: cover;
}

.project-single-info{
  width: 40em;
}