.banner-img img{
  height: 23em;
  width: 35em;
  object-fit: cover;
}
.contact-main{
  margin-top: 4em;
  height: 80vh;
}

.banner-content{
  width: 60em;
}

.successfull-message{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.form-box{
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}