*{
  box-sizing: border-box;
  top: 0em;
  left: 0em;
  padding: 0em;
  margin: 0em;
  font-family: 'Poppins', sans-serif;
}

.btn-green{
  padding: 0.3rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  border: 1px solid green;
  color: green;
  font-size: 0.85rem;
}
.btn-white{
  padding: 0.3rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  border: 1px solid white;
  color: white;
  font-size: 0.85rem;
}
.btn-red{
  padding: 0.3rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  border: 1px solid rgb(255, 0, 0);
  color: rgb(255, 0, 0);
  font-size: 0.85rem;
}

.section-break{
  padding-top: 4em;
  padding-bottom: 4em;
}

.title{
  font-size: 1.6em;
  font-weight: 400;
}
.title-white{
  font-size: 2.4em;
  font-weight: 700;
  color: white;
}
.title-red{
  color: #2E8A35;
}

.bg-height{
  min-height: 100vh;
}


.under-construction img{
  width: 100vw;
  height: 100vh;
  object-fit: contain;

}


@media (max-width: 768px) {
  .desktop-size{
    content-visibility: hidden;
    visibility: hidden;
    display: none;
  }
}
@media (min-width: 800px) {
  .mobile-size{
    content-visibility: hidden;
    visibility: hidden;
    display: none;

  }
}