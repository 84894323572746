.gallery-banner{
  position: relative;
  display: inline-block;
}

.gallery-banner::after{
  content: '';
  position: absolute;
  top: 0;left: 0;
  width: 100vw;
}

.gallery-content{
  position: absolute;
  top: 60%;
  left: 45%;
}

.image-popup{
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.666);
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  z-index: 100;
  display: none;
}

.image-container .image-popup span{
  position: absolute;
  top: 15vh;
  left: 90vw;
  cursor: pointer;
  z-index: inherit;
  color: aliceblue;
}

.image-container .image-popup img{
  position: absolute;
  top: 50%;left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid white;
  width: 45vw;
  object-fit: cover;
}

@media (max-width:780px) {
  .image-container .image-popup img{
    width: 70vw;
  }
}