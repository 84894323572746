.home-hero {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image{
  display: block;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: top;
  object-fit: cover;
  background-position-y: 23em;
}
.gradient-hero {
  position: relative;
  display:inline-block;
}

.gradient-hero:after{
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 35%,rgb(255, 255, 255) 60%); /* W3C */ 
}

.red-box{
  position: absolute;
  content: '';
  padding-right: 38em;
  padding-bottom: 29em;
  border: 5px solid rgb(150, 208, 161);
  top: 25%;
  left: 6rem;
  z-index: 10;
}

.hero-title-box{
  position: absolute;
  content: '';
  z-index: 11;
  color: black;
  top: 38%;
  left: 11em;
}
.hero-title{
  font-size: 2.4rem;
}
.hero-paragraph{
  padding-top: 0.7em;
  width: 30em;
  margin-bottom: 1em;
}


.choose-figure{
  position: relative;
  display:inline-block;
}

.chooseFigureImg{
  display: block;
  height: 25em;
}

.choose-figure-data{
  position: absolute;
  left: 25%;
  top: 25%;
}

.figures-num{
  color: white;
}
.figures-num h2{
  font-size: 1.6em;
}
.figures-num p{
  font-size: 0.8em;
}
.choose-info{
  width: 58%;
  padding-right: 9em;
}

.offer-lists li{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  text-align: center;
}
.offer-lists li img{
  height: 3.5em;
  width: 3.5em;
  margin-bottom: 2em;
}

.home-offers{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our-story-content p{
  width: 40em;
  margin-top: 2em;
}

.our-story-video video{
  width: 45em;
  height: 100%;
}

.appreciate-vide video{
  width: 42em;
  height: 25em;
}

.motivated-image img{
  width: 30em;
  height: 10em;
  object-fit: cover;

}

.motivated-content{
  width: 70vw;
}