.hero-services img{
  height:50vh;
  min-width:100vw;
  object-fit:cover;
  object-position:bottom;}
.hero-services{position:relative;display:inline-block;}
.hero-services h2{position:absolute;top:60%;left:45%;}
.services-hashlinks{width:80em;}
.infrastructure-list img{
  width:5em;
  height:5em;
  object-fit:contain;
  margin-bottom:1em;}
.construction-container img{width:40em;height:20em;object-fit:cover;}
.water-sector-container img{height:20em;width:100vw;}
.water-sector-img{position:relative;display:inline-block;}
.water-section-content-hero{position:absolute;top:40%;left:35%;}
.region-we-work-content{width:60em;}
.region-we-work-lists li{padding-left:6em;margin-bottom:1em;}
.region-we-work-img img{
  height:15em;
  width:100em;
  object-fit:cover;}
.tim-image img{width:20em;height:23em;object-fit:cover;}
.tim-bio{width: 60em;}
.renewable-img-box img{width:30em;height:20em;object-fit:cover;}
.renewable-content-box{width:35vw;}
.renewable-service-lists li img{
  width:4em;
  height:5em;
  object-fit:contain;
  margin-bottom:1em;}
.renewable-service-lists li img{width:4em;object-fit:contain;}
.renewable-projects-img img{height:15em;object-fit:contain;}
.content-img-section img{width: 30em;}
.content-img-section p{width: 35vw;}
.test-material-list:nth-child(2) {flex-direction: row-reverse;}
.test-material-list div img {width: 60em; height: 12em; object-fit: cover;}
.recruitment-pic-list img{width: 20em; object-fit: cover; height: 14em;}
.k9-offers img{width: 48vw; height:22em; object-fit: cover;}
.peb-container img{width: 40vw; height: 20em; object-fit: cover;}